export const environment = {
  production: true,
  appName: "That's My Buddy",
  apiUrl: 'https://staging.api.thatsmybuddy.com',
  wooCommerceUrl: 'https://staging.thatsmybuddy.com',
  logoUrl: './assets/logo-phugg.svg',
  logoLightUrl: './assets/logo-phugg-light.svg',
  email: 'support@thatsmybuddy.com',
  bucket: 'staging-custom-orders.thatsmybuddy.com',
  exportsBucket: 'phugg-backend-exports-staging',
  amDateFormat: 'MM/dd/yyyy',
  dateFormat: 'yyyy-MM-dd',
  longDateTimeFormat: 'yyyy-MM-dd HH:mm',
  longDateTimeSecondsFormat: 'yyyy-MM-dd HH:mm:ss',
  longDateTimeOnlyUnderscores: 'yyyy_MM_dd_HH_mm',
  locale: 'en-US',
  maxFiles: 3,
  prependToOrderNumber: '8',
  spinoffs: [],
  cache: {
    ttl: {
      orderProductsDistinct: 3600,
      orderProductGiftboxesDistinct: 3600,
      ordersDistinct: 3600,
      usersList: 900,
      scatterAuth: 3600,
      settings: 3600,
      locations: 3600
    }
  },
  sentry: {
    active: true,
    dsn: 'https://98317c05703e4412a5fd1f9982701a6a@sentry.io/3070207',
    environment: 'staging',
    collectFeedback: true
  },
  scatter: {
    externalUploadUrl: 'https://staging.scatter.thatsmybuddy.com/print-templates/upload',
    externalUnzipUrl: 'https://staging.scatter.thatsmybuddy.com/print-templates'
  },
  queues: [
    { key: 'crop-queue', name: 'Crop Queue' },
    { key: 'design-queue', name: 'Design Queue' },
    { key: 'mid-tier-queue', name: 'Mid-tier Queue' },
    { key: 'review-queue', name: 'Review Queue' },
    { key: 'sticker-queue', name: 'Sticker Queue' },
    { key: 'booth-queue', name: 'Booth Queue' },
    { key: 'sublimation-queue', name: 'Sublimation Queue' },
    { key: 'sublimation-review-queue', name: 'Sublimation Review Queue' },
    { key: 'sticker-review-queue', name: 'Sticker Review Queue' }
  ],
  bulkQueues: [
    { key: 'bulk-conceptor-queue', name: 'Bulk Conceptor Queue' },
    { key: 'bulk-design-queue', name: 'Bulk Design Queue' },
    { key: 'bulk-review-queue', name: 'Bulk Review Queue' }
  ],
  exportTypes: [
    { key: 'HEADS', name: 'Heads' },
    { key: 'CROPS', name: 'Crops' },
    { key: 'TEMPLATES', name: 'Print files' },
    { key: 'INHOUSE_APPROVALS', name: 'Inhouse approvals' },
    { key: 'FINISHED_SOCKS', name: 'Finished socks' },
    { key: 'ORDERS', name: 'Orders' },
    { key: 'ORDERSWITHLABELS', name: 'Orders with labels' },
    { key: 'RESHIPMENTS', name: 'Re-shipments' },
    { key: 'INVOICES', name: 'Invoices' },
    { key: 'REFUNDS', name: 'Refunds' },
    { key: 'GIFTBOXES', name: 'Giftboxes' },
    { key: 'NOTE_REVIEWS', name: 'Note reviews' },
    { key: 'CLAIMED_REVIEWS', name: 'Designs claimed for review' },
    { key: 'PHONE_NUMBERS', name: 'Customers that opted in to text messages' },
    { key: 'SIZE_QUANTITIES', name: 'Quantity sold per size' },
    { key: 'INACTIVE_GOOD_HEADS', name: 'Inactive Good Heads' },
    { key: 'EXCLUDED_FROM_MARKETING', name: 'Head Opt Outs' },
    { key: 'LABELS', name: 'Labels' },
    { key: 'SHIPPINGSTATS', name: 'Shipping Stats' },
    { key: 'SORTINGSTATS', name: 'Sorting Stats' },
    { key: 'SHIPPEDORDERS', name: 'Shipped Orders' },
    { key: 'SHIPMENTS', name: 'ShipEngine Shipments' },
    { key: 'ADDRESS_VALIDATIONS', name: 'Address Validations' },
    { key: 'B2BORDERS', name: 'B2B Orders' },
    { key: 'B2BCUSTOMERS', name: 'B2B Customers' },
    { key: 'B2BDESIGNS', name: 'B2B Designs' },
    { key: 'B2BFREEDESIGNERS', name: 'B2B Free Sample Designers' },
    { key: 'B2BFREECONCEPTORS', name: 'B2B Free Sample Conceptors' },
    { key: 'NEWSOCKS', name: 'Approved New Socks' }
  ],
  packagingProductId: 1338515,
  packagingVariationId: 1338517,
  environmentWarning: 'STAGING',
  splitPackagingPdfUrl: 'https://lambda-staging.divvyupsocks.com/split-packaging-pdf',
  cmykToRgbUrl: 'https://lambda-staging.divvyupsocks.com/cmyk-to-rgb',
  sockMockupUrl: 'https://staging.sockmockup.divvyupsocks.com',
  customLogoSocksId: 0,
  customDogSocksId: 0,
  customCatSocksId: 0
};
